//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-208:_3916,_1620,_8140,_936,_5724,_2726,_100,_5396;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-208')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-208', "_3916,_1620,_8140,_936,_5724,_2726,_100,_5396");
        }
      }catch (ex) {
        console.error(ex);
      }

        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function garageRT () {
    return _createElement('div', { 'className': 'cm_garage' }, _createElement('a', {
        'className': 'cm_toggle-dropdown garage-button',
        'title': 'Garage: ' + this.size + ' item(s)',
        'tabIndex': '0',
        'role': 'button'
    }, _createElement('div', { 'className': 'garage-icon-container  small-promo' }, _createElement('span', { 'className': 'garage-label-icon small-promo-icon small-promo-icon--svg' }, [_createElement('svg', {
            'className': 'icon-car',
            'aria-hidden': 'true',
            'focusable': 'false',
            'role': 'presentation',
            'xmlns': 'http://www.w3.org/2000/svg',
            'width': '48',
            'height': '48',
            'viewBox': '0 0 48 48',
            'fill': 'none',
            'key': '2620'
        }, _createElement('path', {
            'd': 'M9.1862 19.186H38.8142C40.9359 19.186 42.9708 20.0289 44.4711 21.5292C45.9713 23.0295 46.8142 25.0643 46.8142 27.186V31.058C46.8142 32.1189 46.3928 33.1363 45.6426 33.8865C44.8925 34.6366 43.8751 35.058 42.8142 35.058H5.2002C4.13933 35.058 3.12191 34.6366 2.37177 33.8865C1.62162 33.1363 1.2002 32.1189 1.2002 31.058V27.178C1.20231 25.0601 2.04418 23.0295 3.54121 21.5313C5.03825 20.0331 7.06828 19.1897 9.1862 19.186Z',
            'stroke': 'currentColor',
            'strokeWidth': '2',
            'strokeLinecap': 'round',
            'strokeLinejoin': 'round'
        }), _createElement('path', {
            'd': 'M5.16211 28.11C5.16211 28.5005 5.23903 28.8873 5.38849 29.2481C5.53795 29.6089 5.75701 29.9368 6.03317 30.2129C6.30933 30.4891 6.63719 30.7081 6.99801 30.8576C7.35883 31.0071 7.74556 31.084 8.13611 31.084C8.52666 31.084 8.91339 31.0071 9.27421 30.8576C9.63503 30.7081 9.96288 30.4891 10.239 30.2129C10.5152 29.9368 10.7343 29.6089 10.8837 29.2481C11.0332 28.8873 11.1101 28.5005 11.1101 28.11C11.1101 27.7194 11.0332 27.3327 10.8837 26.9719C10.7343 26.6111 10.5152 26.2832 10.239 26.0071C9.96288 25.7309 9.63503 25.5118 9.27421 25.3624C8.91339 25.2129 8.52666 25.136 8.13611 25.136C7.74556 25.136 7.35883 25.2129 6.99801 25.3624C6.63719 25.5118 6.30933 25.7309 6.03317 26.0071C5.75701 26.2832 5.53795 26.6111 5.38849 26.9719C5.23903 27.3327 5.16211 27.7194 5.16211 28.11Z',
            'stroke': 'currentColor',
            'strokeWidth': '2',
            'strokeLinecap': 'round',
            'strokeLinejoin': 'round'
        }), _createElement('path', {
            'd': 'M36.8901 28.11C36.8901 28.8987 37.2035 29.6552 37.7612 30.2129C38.3189 30.7707 39.0754 31.084 39.8641 31.084C40.6529 31.084 41.4093 30.7707 41.9671 30.2129C42.5248 29.6552 42.8381 28.8987 42.8381 28.11C42.8381 27.3212 42.5248 26.5648 41.9671 26.0071C41.4093 25.4493 40.6529 25.136 39.8641 25.136C39.0754 25.136 38.3189 25.4493 37.7612 26.0071C37.2035 26.5648 36.8901 27.3212 36.8901 28.11V28.11Z',
            'stroke': 'currentColor',
            'strokeWidth': '2',
            'strokeLinecap': 'round',
            'strokeLinejoin': 'round'
        }), _createElement('path', {
            'd': 'M8.13623 19.186L9.56623 10.6C9.72137 9.67453 10.1995 8.83397 10.9157 8.22761C11.6319 7.62126 12.5398 7.28836 13.4782 7.28802H34.5222C35.4606 7.28836 36.3686 7.62126 37.0848 8.22761C37.801 8.83397 38.2791 9.67453 38.4342 10.6L39.8642 19.184',
            'stroke': 'currentColor',
            'strokeWidth': '2',
            'strokeLinecap': 'round',
            'strokeLinejoin': 'round'
        }), _createElement('path', {
            'd': 'M11.1101 35.05V38.026C11.1101 38.8147 10.7968 39.5712 10.239 40.1289C9.68131 40.6867 8.92486 41 8.13611 41C7.34736 41 6.59091 40.6867 6.03317 40.1289C5.47544 39.5712 5.16211 38.8147 5.16211 38.026V35.05',
            'stroke': 'currentColor',
            'strokeWidth': '2',
            'strokeLinecap': 'round',
            'strokeLinejoin': 'round'
        }), _createElement('path', {
            'd': 'M42.8381 35.05V38.026C42.8381 38.8147 42.5248 39.5712 41.9671 40.1289C41.4093 40.6867 40.6529 41 39.8641 41C39.0754 41 38.3189 40.6867 37.7612 40.1289C37.2035 39.5712 36.8901 38.8147 36.8901 38.026V35.05',
            'stroke': 'currentColor',
            'strokeWidth': '2',
            'strokeLinecap': 'round',
            'strokeLinejoin': 'round'
        }))], this.size ? [_createElement('span', {
            'className': 'garage-size site-header-cart--count visible',
            'key': 'garage-size',
            'data-header-cart-count': this.size
        })] : null), _createElement('div', { 'className': 'garage-label small-promo-content' }, _createElement('span', { 'className': 'small-promo-content_heading' }, 'Select Your Vehicle'), _createElement('div', { 'className': 'garage-sublabel small-promo-content--desktop' }, !this.isVehicleSelected ? ['Year, Make & Model'] : null, this.isVehicleSelected ? [this.vehicleString] : null)), this.isDropdownVisible ? [_createElement('svg', {
            'className': 'cm_icon cm_icon-angle up',
            'height': '20px',
            'role': 'img',
            'viewBox': '39 30 565 565',
            'aria-hidden': 'true',
            'key': '38290'
        }, _createElement('path', { 'd': 'M600,189q0-7-6-12l-28-28q-5-6-12-6t-13,6l-220,219-219-219q-5-6-13-6t-12,6l-28,28q-6,5-6,12t6,13l260,260q5,6,12,6t13-6l260-260q6-5,6-13z' }))] : null, !this.isDropdownVisible ? [_createElement('svg', {
            'className': 'cm_icon cm_icon-angle down',
            'height': '20px',
            'role': 'img',
            'viewBox': '39 30 565 565',
            'aria-hidden': 'true',
            'key': '41520'
        }, _createElement('path', { 'd': 'M600,189q0-7-6-12l-28-28q-5-6-12-6t-13,6l-220,219-219-219q-5-6-13-6t-12,6l-28,28q-6,5-6,12t6,13l260,260q5,6,12,6t13-6l260-260q6-5,6-13z' }))] : null)), [this.garageDropdown(function () {
            function repeatItems1(items, itemsIndex) {
                return [items(function () {
                        return _createElement('div', { 'className': 'garage-item' + (this.isSelected ? ' selected' : '') }, _createElement('span', {
                            'className': 'select-model',
                            'data-cm-role': 'select-vehicle'
                        }, this.vehicleString), _createElement('span', {
                            'className': 'remove-model',
                            'data-cm-role': 'remove-vehicle'
                        }, [_createElement('svg', {
                                'className': 'cm_icon cm_icon-times',
                                'height': '20px',
                                'role': 'image',
                                'viewBox': '0 0 22 22',
                                'key': '2470'
                            }, _createElement('path', { 'd': 'M1,1L21,21M21,1L1,21' }))]));
                    }, { count: undefined })];
            }
            return _createElement('div', { 'className': 'cm_garageDropdown' }, _createElement('div', { 'className': 'garage-header' }, _createElement('div', { 'className': 'garage-title' }, 'MY VEHICLE', !this.items || this.items.length >= 2 ? 'S' : ''), _createElement('div', { 'onClick': this.closeGarage }, _createElement('svg', {
                'className': 'garage-close',
                'width': '14',
                'height': '14',
                'viewBox': '0 0 14 14',
                'fill': 'none',
                'xmlns': 'http://www.w3.org/2000/svg'
            }, _createElement('path', {
                'd': 'M14 1.056L12.944 0L7 5.944L1.056 0L0 1.056L5.944 7L0 12.944L1.056 14L7 8.056L12.944 14L14 12.944L8.057 7L14 1.056Z',
                'fill': '#16181B'
            })))), this.template === 'list' ? _createElement('div', {
                'className': 'cmTemplate_list',
                'key': '495'
            }, _createElement('div', { 'className': 'garage-content' }, _createElement.apply(this, [
                'div',
                { 'className': 'cmRepeater_items' },
                _map(this.items, repeatItems1.bind(this))
            ]), _createElement('div', {
                'className': 'clear-garage',
                'data-cm-role': 'clear-garage'
            }, 'Clear garage'), [this.vehicleWidget(function () {
                    function repeatSelects1(selects, selectsIndex) {
                        return [selects(function () {
                                function repeatEntry1(entry, entryIndex) {
                                    return _createElement('option', {
                                        'disabled': this.loading,
                                        'value': entry.term,
                                        'key': entry.value
                                    }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                                }
                                function repeatEntry2(entry, entryIndex) {
                                    return _createElement('option', {
                                        'disabled': this.loading,
                                        'value': entry.term,
                                        'key': entry.value
                                    }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                                }
                                function repeatEntry3(entry, entryIndex) {
                                    return _createElement('option', {
                                        'disabled': this.loading,
                                        'value': entry.term,
                                        'key': entry.value
                                    }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                                }
                                function repeatEntry4(entry, index) {
                                    return _createElement('div', {
                                        'className': 'option' + (this.isSearchable && this.activeIndex === index || !this.isSearchable && entry.selected ? ' cm_checked' : ''),
                                        'key': entry.value,
                                        'onClick': () => this.onChange(entry.term)
                                    }, this.showCheckboxes ? _createElement('input', {
                                        'type': 'checkbox',
                                        'readOnly': true,
                                        'checked': entry.selected,
                                        'className': 'cm_dropdown_checkbox cm_facet_checkbox',
                                        'key': '3573'
                                    }) : null, _createElement('div', { 'className': 'cm_dropdown_value' }, entry.value), this.showHitCount ? _createElement('div', {
                                        'className': 'cm_dropdown_hitCount',
                                        'key': '3775'
                                    }, entry.hitCount) : null);
                                }
                                return _createElement('div', { 'className': this.extraClassName }, !this.isSearchable ? _createElement('div', {
                                    'className': 'cm_select_toggle' + (this.useNativeDropdown ? ' cm_select_toggle__native' : '') + ' cm_toggle-dropdown',
                                    'key': '39'
                                }, _createElement('select', {
                                    'className': 'cm_select_inner-select cm_select__pretty',
                                    'onChange': this.onChange,
                                    'value': this.useNativeDropdown ? this.firstSelectedTerm || '' : '_current',
                                    'disabled': this.disabled,
                                    'aria-label': this.title
                                }, this.useNativeDropdown ? [
                                    !(this.hideNullOption && this.entries.some(e => e.selected)) ? _createElement('option', {
                                        'value': '',
                                        'key': 'null-option'
                                    }, '\n          ', '', this.title, '\n        ') : null,
                                    this.loading ? _createElement('option', {
                                        'key': 'loading-option',
                                        'disabled': true
                                    }, '...loading...') : null,
                                    this.title === 'Make' && (this.entries.some(entry => entry.payload === 'Popular') && this.entries.some(entry => entry.payload !== 'Popular')) ? [
                                        _createElement('option', {
                                            'className': 'cm_option_title',
                                            'disabled': true,
                                            'key': '7801'
                                        }, 'Popular Makes'),
                                        _map(this.entries.filter(entry => entry.payload === 'Popular'), repeatEntry1.bind(this)),
                                        _createElement('option', {
                                            'className': 'cm_option_title',
                                            'disabled': true,
                                            'key': '7804'
                                        }, 'All Makes'),
                                        _map(this.entries.filter(entry => entry.payload !== 'Popular'), repeatEntry2.bind(this))
                                    ] : null,
                                    this.title !== 'Make' || !(this.entries.some(entry => entry.payload === 'Popular') && this.entries.some(entry => entry.payload !== 'Popular')) ? [_map(this.entries, repeatEntry3.bind(this))] : null
                                ] : null, !this.useNativeDropdown ? _createElement('option', {
                                    'key': '_current',
                                    'value': '_current'
                                }, '\n        ', this.selectedEntries.length ? `${ this.selectedEntries.map(e => e.value).join(', ') }` : `${ '' } ${ this.title }`, '\n      ') : null)) : null, this.isSearchable ? [[this.filterInput(function () {
                                            return _createElement('div', { 'className': 'cm_filterInput' });
                                        }, {
                                            widgetName: 'undefined',
                                            items: undefined
                                        })]] : null, !this.disabled && !this.useNativeDropdown ? _createElement.apply(this, [
                                    'div',
                                    {
                                        'className': 'cm_dropdown cm_select_dropdown cm_hide',
                                        'aria-label': this.title,
                                        'key': '2869'
                                    },
                                    !this.hideNullOption ? _createElement('div', {
                                        'className': 'option',
                                        'key': 'null-option',
                                        'onClick': () => this.onChange('')
                                    }, '\n      ', '', this.title, '\n    ') : null,
                                    this.loading ? _createElement('div', {
                                        'className': 'option',
                                        'key': 'null-option',
                                        'disabled': true
                                    }, '...loading...') : null,
                                    !this.loading ? _map(this.entries, repeatEntry4.bind(this)) : null
                                ]) : null);
                            }, { count: undefined })];
                    }
                    return _createElement('div', { 'className': 'cm_vehicle-widget cm_vehicle-widget__garage cm_vehicleWidget' }, _createElement('div', { 'className': 'cm_vehicle-widget_dropdowns' }, _createElement('div', { 'className': 'cm_vehicle-widget_body' }, _createElement.apply(this, [
                        'div',
                        { 'className': 'cm_vehicle-widget_select-container  cmRepeater_selects' },
                        _map(this.selects, repeatSelects1.bind(this))
                    ]), _createElement('div', { 'className': 'cm_vehicle-widget_button-container' }, _createElement('div', {
                        'className': 'cm_vehicle-widget_button cm_vehicle-widget_button__go cm_button button-primary',
                        'onClick': this.selectVehicle,
                        'disabled': !this.allowToSetVehicle,
                        'role': 'button',
                        'tabIndex': this.allowToSetVehicle ? '0' : '-1'
                    }, '\n    GO\n  '), _createElement('div', {
                        'className': 'cm_vehicle-widget_button cm_vehicle-widget_button__clear cm_button button-secondary',
                        'onClick': this.discardVehicle,
                        'disabled': !this.allowToDiscardVehicle,
                        'role': 'button',
                        'tabIndex': this.allowToDiscardVehicle ? '0' : '-1'
                    }, '\n    Clear\n  ')))));
                }, {
                    widgetName: 'undefined',
                    items: undefined
                })])) : null, this.template === 'empty' ? _createElement('div', {
                'className': 'cmTemplate_empty',
                'key': '9008'
            }, _createElement('div', { 'className': 'garage-content' }, _createElement('p', { 'className': 'vehicle-advantages' }, 'Store your vehicle in the garage'), _createElement('p', { 'className': 'vehicle-advantages' }, 'Get products for your vehicle'), _createElement('p', { 'className': 'vehicle-advantages' }, 'Easily find the parts you need'), [this.vehicleWidget(function () {
                    function repeatSelects1(selects, selectsIndex) {
                        return [selects(function () {
                                function repeatEntry1(entry, entryIndex) {
                                    return _createElement('option', {
                                        'disabled': this.loading,
                                        'value': entry.term,
                                        'key': entry.value
                                    }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                                }
                                function repeatEntry2(entry, entryIndex) {
                                    return _createElement('option', {
                                        'disabled': this.loading,
                                        'value': entry.term,
                                        'key': entry.value
                                    }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                                }
                                function repeatEntry3(entry, entryIndex) {
                                    return _createElement('option', {
                                        'disabled': this.loading,
                                        'value': entry.term,
                                        'key': entry.value
                                    }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                                }
                                function repeatEntry4(entry, index) {
                                    return _createElement('div', {
                                        'className': 'option' + (this.isSearchable && this.activeIndex === index || !this.isSearchable && entry.selected ? ' cm_checked' : ''),
                                        'key': entry.value,
                                        'onClick': () => this.onChange(entry.term)
                                    }, this.showCheckboxes ? _createElement('input', {
                                        'type': 'checkbox',
                                        'readOnly': true,
                                        'checked': entry.selected,
                                        'className': 'cm_dropdown_checkbox cm_facet_checkbox',
                                        'key': '3573'
                                    }) : null, _createElement('div', { 'className': 'cm_dropdown_value' }, entry.value), this.showHitCount ? _createElement('div', {
                                        'className': 'cm_dropdown_hitCount',
                                        'key': '3775'
                                    }, entry.hitCount) : null);
                                }
                                return _createElement('div', { 'className': this.extraClassName }, !this.isSearchable ? _createElement('div', {
                                    'className': 'cm_select_toggle' + (this.useNativeDropdown ? ' cm_select_toggle__native' : '') + ' cm_toggle-dropdown',
                                    'key': '39'
                                }, _createElement('select', {
                                    'className': 'cm_select_inner-select cm_select__pretty',
                                    'onChange': this.onChange,
                                    'value': this.useNativeDropdown ? this.firstSelectedTerm || '' : '_current',
                                    'disabled': this.disabled,
                                    'aria-label': this.title
                                }, this.useNativeDropdown ? [
                                    !(this.hideNullOption && this.entries.some(e => e.selected)) ? _createElement('option', {
                                        'value': '',
                                        'key': 'null-option'
                                    }, '\n          ', '', this.title, '\n        ') : null,
                                    this.loading ? _createElement('option', {
                                        'key': 'loading-option',
                                        'disabled': true
                                    }, '...loading...') : null,
                                    this.title === 'Make' && (this.entries.some(entry => entry.payload === 'Popular') && this.entries.some(entry => entry.payload !== 'Popular')) ? [
                                        _createElement('option', {
                                            'className': 'cm_option_title',
                                            'disabled': true,
                                            'key': '7801'
                                        }, 'Popular Makes'),
                                        _map(this.entries.filter(entry => entry.payload === 'Popular'), repeatEntry1.bind(this)),
                                        _createElement('option', {
                                            'className': 'cm_option_title',
                                            'disabled': true,
                                            'key': '7804'
                                        }, 'All Makes'),
                                        _map(this.entries.filter(entry => entry.payload !== 'Popular'), repeatEntry2.bind(this))
                                    ] : null,
                                    this.title !== 'Make' || !(this.entries.some(entry => entry.payload === 'Popular') && this.entries.some(entry => entry.payload !== 'Popular')) ? [_map(this.entries, repeatEntry3.bind(this))] : null
                                ] : null, !this.useNativeDropdown ? _createElement('option', {
                                    'key': '_current',
                                    'value': '_current'
                                }, '\n        ', this.selectedEntries.length ? `${ this.selectedEntries.map(e => e.value).join(', ') }` : `${ '' } ${ this.title }`, '\n      ') : null)) : null, this.isSearchable ? [[this.filterInput(function () {
                                            return _createElement('div', { 'className': 'cm_filterInput' });
                                        }, {
                                            widgetName: 'undefined',
                                            items: undefined
                                        })]] : null, !this.disabled && !this.useNativeDropdown ? _createElement.apply(this, [
                                    'div',
                                    {
                                        'className': 'cm_dropdown cm_select_dropdown cm_hide',
                                        'aria-label': this.title,
                                        'key': '2869'
                                    },
                                    !this.hideNullOption ? _createElement('div', {
                                        'className': 'option',
                                        'key': 'null-option',
                                        'onClick': () => this.onChange('')
                                    }, '\n      ', '', this.title, '\n    ') : null,
                                    this.loading ? _createElement('div', {
                                        'className': 'option',
                                        'key': 'null-option',
                                        'disabled': true
                                    }, '...loading...') : null,
                                    !this.loading ? _map(this.entries, repeatEntry4.bind(this)) : null
                                ]) : null);
                            }, { count: undefined })];
                    }
                    return _createElement('div', { 'className': 'cm_vehicle-widget cm_vehicle-widget__garage cm_vehicleWidget' }, _createElement('div', { 'className': 'cm_vehicle-widget_dropdowns' }, _createElement('div', { 'className': 'cm_vehicle-widget_body' }, _createElement.apply(this, [
                        'div',
                        { 'className': 'cm_vehicle-widget_select-container  cmRepeater_selects' },
                        _map(this.selects, repeatSelects1.bind(this))
                    ]), _createElement('div', { 'className': 'cm_vehicle-widget_button-container' }, _createElement('div', {
                        'className': 'cm_vehicle-widget_button cm_vehicle-widget_button__go cm_button button-primary',
                        'onClick': this.selectVehicle,
                        'disabled': !this.allowToSetVehicle,
                        'role': 'button',
                        'tabIndex': this.allowToSetVehicle ? '0' : '-1'
                    }, '\n    GO\n  '), _createElement('div', {
                        'className': 'cm_vehicle-widget_button cm_vehicle-widget_button__clear cm_button button-secondary',
                        'onClick': this.discardVehicle,
                        'disabled': !this.allowToDiscardVehicle,
                        'role': 'button',
                        'tabIndex': this.allowToDiscardVehicle ? '0' : '-1'
                    }, '\n    Clear\n  ')))));
                }, {
                    widgetName: 'undefined',
                    items: undefined
                })])) : null);
        }, {
            widgetName: 'undefined',
            items: undefined
        })]);
}
        export const componentNames = ["cm:filterInput","cm:vehicleWidget","cm:filterInput","cm:vehicleWidget","cm:garageDropdown"]